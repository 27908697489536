.profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
  

  
}

.profileimage {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  cursor:pointer;
  
  
  
}

.membername{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 10px;
  

}

.iontitle-member{
  cursor: pointer;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.iontitle-member:hover{
  text-decoration: underline;
}

.card-container {
  display: flex;
  padding: 5px;
  width: auto;
}
.trash-button{
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
}
