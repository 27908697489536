.wrapper-my-coach {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    margin: auto;
  }
  
  .wrapper-my-coach img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .qualifications {
    font-size: 15px;
  }