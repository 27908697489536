.sortChip {
    margin-right: 10px;
    padding: auto;
    width: 100px;
    justify-content: center;
  }
  
  .sortChipSecondary {
    padding: auto;
    width: 110px;
    justify-content: center;
  }
  
  .centerChips {
    margin: 0 auto;
  }
