.selectGroup {
    width: 600px;
    --border-color: #ccc;
    --border-radius: 4px;
    --border-style: solid;
    --border-width: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}