.koroQuestionToolbar {
    --background: white;
    margin-top: 10px;
}


.koroQuestionToolbarWords{
    font-size: 20px;
}

.colorPrimary{
    color: var(--ion-color-primary);
}

.fullscreenModal {
    --width:100% !important;
    --height:100% !important;
}