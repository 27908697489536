.goal {
    --border-radius: 10px;
    margin: 5px;
  }
  
  .need-goal {
    --border-color: #456673;
    --border-radius: 10px;
    --border-width: 1px;
    --color: #456673;
  }
  
  
  .viewAll {
    font-size: 13px;
    text-decoration-line: underline;
  }
  
  .goal-input {
    border-radius: 10px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  
  .ion-accordion-toggle-icon {
    font-size: 1px;
    color: rgb(255, 255, 255);
  }
  
  .progress-circle {
    margin: auto;
  }
  
  .create-first-goal{
    text-decoration-line: underline;
  }