.session-date {
    font-size: 21px;
    text-align: left;
    font-weight: bolder;
    padding: 5px 0px;
    color: #93767a;
  }
  
  .session {
    display: flex;
    width: 270px;
    flex-direction: column;
    border: 2px solid #243841;
    border-radius: 10px;
    margin-bottom: 10px;
    font-family: 'Times New Roman', Times, serif;
    
  }
  
  .session-detail {
    display: flex;
    cursor: pointer;
    justify-content: center;
    text-decoration: none;
    font-size: 18px;
  }
  .session-detail:hover{
    text-decoration: underline;
    color: #93767a;
    
  }
  
  .session-member {
    display: flex;
    font-size: 16px;
  }
  
  .details {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .detail-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    margin-left: 5px;
  }
  
  .detail-icon>img {
    width: 30px;
    height: 28px;
  }
  
  .details-location,
  .details-time {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
  }
  
  .details-time{
    font-weight: bold;
  }
  
  .date-today,
  .date-tomorrow {
    font-size: 20px;
    font-weight: bolder;
    color: #243841;
  }
  
  .session-status {
    display: flex;
    justify-content: center;
    background-color: red;
    font-size: large;
    font-weight: bolder;
    color: white;
    border-radius: 10px;
    margin: 5px 60px;
  }
  .info {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-top: 2px solid #93767a;
    margin: 0px 5px;
    padding: 2px;
  }
  
  .info-name,
  .info-email {
    display: flex;
    align-items: center;
  }
  
  .info-email ion-icon, .info-name ion-icon, .details-time ion-icon {
    margin-right: 5px;
  }
  
  .info-name {
    cursor: pointer;
    text-decoration: none;
  }
  
  .info-name:hover {
    text-decoration: underline;
  }