.status-chip-success {
  background-color: var(--ion-color-success);
}

.status-chip-warning {
  background-color: #ffd534;
}

.status-chip-danger {
  background-color: var(--ion-color-danger);
}