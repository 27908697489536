
.solo-box-cont{
    width: 500px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: auto;
    padding-left: 10px;
}
.small-box-cont{
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: auto;
    padding-left: 10px;
}

.wrong {
    color : red;
    border: 1px solid red;
}


.mult-box-cont{
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: auto;
    padding-left: 10px;
}

.input-title{
    font-size: 2px;
    color: rgb(157, 157, 157);
}






