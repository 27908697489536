.info-p {
  font-size: 14px;
  color: #828282;
}

.title-p {
  font-size: 15px;
  color: #393838;
}

.bp-sub {
  font-size: 13px;
  color: #393838;
}

p {
  margin: 0px;
}

.result-box {
  text-align: center;
  padding: 10px;
  border: 1px solid #e6e6e6;
}
