.messageRight{
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
}

.messageLeft{
    display: flex;
    justify-content: flex-start;
    padding-top: 10px;
}

